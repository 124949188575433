<template>
  <TitleBar>Select Campaign</TitleBar>

  <div class="flex-row p-2">
    <div v-if="availableCampaigns">
      <CampaignSelectorItem
        :campaign="campaign"
        :key="campaign.id"
        v-for="campaign in availableCampaigns"
      />
    </div>
  </div>
</template>

<script>
import CampaignSelectorItem from "@/components/CampaignSelectorItem";
import TitleBar from "@/components/TitleBar";
import { useAuthStore } from "@/stores/auth";
import { useAppStore } from "@/stores/app";
export default {
  components: { TitleBar, CampaignSelectorItem },
  setup() {
    const authStore = useAuthStore();
    const appStore = useAppStore();
    return { authStore, appStore };
  },
  data() {
    return {
      selectedCampaign: null,
    };
  },
  computed: {
    availableCampaigns() {
      return this.authStore.availableCampaigns;
    },
  },
  async mounted() {
    if (this.availableCampaigns.length == 1) {
      this.appStore.setCurrentCampaign(this.availableCampaigns[0]);
      this.$router.push({ name: "dashboard" });
    }
  },
};
</script>
